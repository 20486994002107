import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import NonStretchedImage from '../../components/layout/NonStretchedImage';

import { ViewportBreakpoints } from '../../components/cssConstants';

import FeatureListBreaker, { Features } from '../../components/cta/FeatureListBreaker';
import GetStarted from '../../components/cta/GetStarted';
import FeatureRow from '../../components/section/FeatureRow';

import HeroH1 from '../../components/hero/HeroH1';
import HeroH2 from '../../components/hero/HeroH2';

import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import UL from '@rotaready/frecl/build/UL';
import TextListItem from '@rotaready/frecl/build/TextListItem';

const DarkWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.brand180};
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 1440px 519px;
  background-image: url(${props => props.backgroundImage});
`;

const Hero = styled.div`
  padding: 80px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 120px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    text-align: left;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 45%;
    margin-right: 80px;
  }
`;

const HeroIllustration = styled.div`
  flex-grow: 1;

  .gatsby-image-wrapper {
    width: 100%;
    margin: 0 auto;
  }
`;

const CtaButtons = styled.div`
  margin: 30px auto;
  max-width: 180px;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 40px;
    max-width: unset;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const Subtitle = styled(Text)`
  margin: 20px 0 40px 0;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme: { colors } }) => colors.brand160};
`;

const header = {
  backgroundColor: 'brand180',
  invertColors: true,
};

const Page = ({ data }) => (
  <Body header={header}>
    <SEO
      title="Demand forecasting - Rotaready"
      description="Build staff rotas to accurate demand forecasts, informed by reservations, upcoming events, seasonality and more. Achieve perfect staffing levels to meet demand."
      url="demand-forecasting"
    />
    <DarkWrapper
      backgroundImage={data.heroBackgroundMask.childImageSharp.fixed.src}
      title="Demand forecasting"
    >
      <ResponsiveContainer>
        <Hero>
          <HeroInnerContainer>
            <HeroBody>
              <HeroH1 color="brand" text="Demand forecasting" />
              <HeroH2 color="white" text="Your crystal ball for staff scheduling" />

              <H4 uistyle="white">
                Build rotas that cater for future demand, without needing a Maths degree
                and a team of boffins.
                <br />
                <br />
                With demand forecasting, your staff schedules are informed by past
                trends, upcoming events, seasonality and little things like the
                weather; meaning you'll always have the ideal number of people
                on shift.
              </H4>

              <CtaButtons>
                <Link to="/demo">
                  <Button uistyle="primary" text="Request demo" size="lg" />
                </Link>
                <Link to="/contact">
                  <Button uistyle="white" text="Speak to the team" size="lg" ghost borderless />
                </Link>
              </CtaButtons>
            </HeroBody>

            <HeroIllustration>
              <NonStretchedImage fluid={data.heroImage.childImageSharp.fluid} />
            </HeroIllustration>
          </HeroInnerContainer>
        </Hero>
      </ResponsiveContainer>
    </DarkWrapper>

    <FeatureListBreaker
      rows={1}
      excludeFeature={Features.DEMAND_FORECASTING}
      headingText="Other features"
    />

    <FeatureRow
      mirrorArrange
      title="Staff rotas perfectly aligned with demand"
      illustrationImage={data.forecastIntegrationsIllustration.childImageSharp.fluid}
      illustrationAltText="Demand forecasting tool, utilising data from till, EPOS and reservation systems"
    >
      <Subtitle text="Using either your own historical data or trends in your industry, Rotaready's demand forecasting will take your rotas to the next level." />

      <UL size="xl" uistyle="normal" iconUistyle="primary">
        <TextListItem text="Demand forecasted with advanced AI, modelling and deep learning techniques" />
        <TextListItem text="Ready to go from day one" />
        <TextListItem text="No need for in-person observation studies or bespoke consultancy" />
        <TextListItem text="Powerful pooling technology that harnesses trends from similar businesses in your industry" />
      </UL>
    </FeatureRow>

    <FeatureRow
      title="Account for one-off events & seasonality"
      illustrationImage={data.eventsIllustration.childImageSharp.fluid}
      illustrationAltText="Analysis of past reservations, weather and events to build a demand forecast"
    >
      <Subtitle text="Automatically aggregate data from many different sources to ensure your rotas cater for more than just the average day. Our advanced rota software is able to take into account:" />

      <UL size="xl" uistyle="normal" iconUistyle="primary">
        <TextListItem text="Local events, national holidays and your own events" />
        <TextListItem text="Changes in the weather, using an accurate local forecast for each venue" />
        <TextListItem text="Past and future reservations, covers and bookings" />
        <TextListItem text="Historical sales & transactions" />
      </UL>
    </FeatureRow>

    <FeatureRow
      mirrorArrange
      title="Settings tailored to your needs"
      illustrationImage={data.settingsIllustration.childImageSharp.fluid}
      illustrationAltText="Demand forecasting settings within Rotaready"
    >
      <Subtitle text="Not every business is the same. That's why Rotaready lets you customise settings, data sources and more." />

      <UL size="xl" uistyle="normal" iconUistyle="primary">
        <TextListItem text="Forecast sales and demand as a whole or per revenue stream" />
        <TextListItem text="Toggle the influence of weather, sales and reservations" />
        <TextListItem text="Opt-in to trends and aggregated data from other businesses in your industry" />
        <TextListItem text="Enable partner integrations at no extra cost" />
      </UL>
    </FeatureRow>

    <GetStarted />
  </Body>
);

export default Page;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "misc/hero-demand-forecasting.png" }) {
      childImageSharp {
        fluid(maxWidth: 577, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    heroBackgroundMask: file(relativePath: { eq: "misc/hero-mask-brand180.png" }) {
      childImageSharp {
        fixed(height: 519, width: 1440, quality: 100) {
          src
        }
      }
    }

    forecastIntegrationsIllustration: file(relativePath: { eq: "illustrations/demand-forecast-integrations.png" }) {
      childImageSharp {
        fluid(maxWidth: 497, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    eventsIllustration: file(relativePath: { eq: "illustrations/demand-events.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    settingsIllustration: file(relativePath: { eq: "illustrations/demand-settings.png" }) {
      childImageSharp {
        fluid(maxWidth: 365, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
  }
`;
